import Wpp from '../../wpp-png-removebg-preview.png';
import './whats.css';

function Whats(){
    return(
        <div>
            <a href='https://wa.me/554130783583' target='_blank'>
                <img src={Wpp}></img>
            </a>
        </div>
    )
}

export default Whats;