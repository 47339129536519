import React from 'react';
import Zoom from 'react-reveal/Zoom';
import './testimonial.css'

import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Casos',
    paragraph: 'Veja relatos dos pacientes:'
  };

  return (
    <section id="casos" {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <Zoom top>
            <SectionHeader data={sectionHeader} className="center-content" />
          </Zoom>

          <div className={`center-container ${tilesClasses}`}>
            
            {/* Primeira coluna */}
            <div className="testimonial-grid">

              <Zoom left>
                <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                  <div className="tiles-item-inner bloco">
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0 text-color-depoimentos">
                        Dr. João Guilherme transmite muita confiança e credibilidade em seu trabalho. Seu conhecimento técnico e sua abordagem cuidadosa fazem toda a diferença. Além disso, o consultório é um ambiente aconchegante e moderno, proporcionando conforto e tranquilidade. Não é à toa que ele cuida da minha saúde bucal desde 1998.
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-name text-color-high">Ana Luiza Wagner</span>
                    </div>
                  </div>
                </div>
              </Zoom>

              <Zoom bottom>
                <div className="tiles-item reveal-from-bottom">
                  <div className="tiles-item-inner bloco">
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0 text-color-depoimentos">
                        O Dr. João Guilherme é um excelente profissional, onde procura com razão e carisma atender as necessidades de seus pacientes. Estive fora do país por alguns anos e nesse tempo fiz quimioterapia, o que enfraqueceu meus dentes e me deixou com medo de sorrir novamente. Procurei o consultório e com todo o cuidado e técnica atualizada, tive meus dentes recuperados e revitalizados.
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-name text-color-high">Marco Aurélio Ferreira Pinto</span>
                    </div>
                  </div>
                </div>
              </Zoom>

            </div>

            {/* Segunda coluna */}
            <div className="testimonial-grid">

              <Zoom right>
                <div className="tiles-item reveal-from-left" data-reveal-delay="200">
                  <div className="tiles-item-inner bloco">
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0 text-color-depoimentos">
                        Dr João Guilherme, não tenho palavras para agradecer o quanto sou grata por esses anos de atendimento por todos da minha família,sou grata a Deus por permitir o Sr. em nossas vidas, obrigada por tanto carinho e pelo atendimento sempre impecável!
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-name text-color-high">Maria Helena da Silva</span>
                    </div>
                  </div>
                </div>
              </Zoom>

              <Zoom right>
                <div className="tiles-item reveal-from-left" data-reveal-delay="200">
                  <div className="tiles-item-inner bloco">
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0 text-color-depoimentos">
                        Desde criança, sempre tive muitos problemas com os dentes. No entanto, há uns 18 anos, conheci o Dr. João Guilherme. Ele cuida dos meus dentes de forma extremamente profissional e, graças a ele, hoje meu sorriso é maravilhoso!
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                      <span className="testimonial-item-name text-color-high">Denise Etzel</span>
                    </div>
                  </div>
                </div>
              </Zoom>

            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
