import React from 'react';
import Zoom from 'react-reveal/Zoom';

import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Área de Atuação',
    paragraph: ''
  };

  return (
    <section id="area"
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <Zoom top>
            <SectionHeader data={sectionHeader} className="center-content" />
          </Zoom>

          <div className={splitClasses}>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <Zoom bottom>
                  <div className="text-xxs text-color-secondary fw-600 tt-u mb-8">
                    Harmonização Orofacial: 
                    <br/>
                    <ul>
                      <li>Toxina Botulínica (Botox)</li>
                      <li>PRF (Botox)</li>
                      <li>Preenchimentos (labial, bigode chinês, olheiras, mandíbula)</li>
                    </ul>
                  </div>
                  <h3 className="mt-0 mb-12">
                  </h3>
                </Zoom>

                <Zoom top>
                  <p className="m-0 text-color-textos text-center" style={{textAlign: 'justify', textJustify: 'inter-word'}}>
                  A harmonização orofacial é um conjunto de procedimentos estéticos e funcionais que visam equilibrar e melhorar a estética facial e a função da região oral. Por meio de técnicas como preenchimentos, toxina botulínica e outros tratamentos, a harmonização orofacial pode corrigir assimetrias faciais, rejuvenescer a aparência, suavizar rugas e linhas de expressão, além de promover a harmonia estética entre os diferentes elementos do rosto.
                  </p>
                </Zoom>
              </div>
              <Zoom right>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/Botox Amanda.jpeg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </Zoom>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <Zoom bottom>
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Odontologia:
                    <ul>
                      <li>Lentes de Contato</li>
                      <li>Resinas</li>
                      <li>Clareamento</li>
                    </ul>
                  </div>
                </Zoom>

                <h3 className="mt-0 mb-12">
                </h3>
                <Zoom top>
                  <p className="m-0 text-color-textos" style={{textAlign: 'justify', textJustify: 'inter-word'}}>
                  </p>
                </Zoom>
              </div>

              <Zoom left>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/botox.png')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
              </Zoom>

            </div>

           

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
